<template>
  <div class="floors">
    <v-data-table
      :headers="headers"
      :items="floors"
      hide-default-footer
      disable-pagination
      :no-data-text="noData"
      v-sortable-data-table
      @sorted="saveOrder"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar elevation="1" height="100">
          <v-dialog v-model="dialog" max-width="500px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-container>
                <v-spacer></v-spacer>
                <div class="text-center">
                  <v-btn color="success" rounded v-bind="attrs" v-on="on">
                    <v-icon>fa-plus</v-icon>
                  </v-btn>
                  <div class="caption">Agregar Planta</div>
                </div>
                <v-spacer></v-spacer>
              </v-container>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Planta"
                          :color="whiteLabel.colors.primary"
                          :rules="requiredRuleName"
                          @input="inputChanged"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :color="whiteLabel.colors.primary"
                  text
                  @click="close"
                  :disabled="loading"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="save"
                  :loading="loading"
                  :disabled="!isValid"
                >
                  Guardar
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px" persistent>
            <v-card>
              <v-card-title class="headline"
                >Desea eliminar la planta "{{ editedItem.name }}"?</v-card-title
              ><v-card-text
                >ATENCIÓN: Se eliminarán todas las unidades de la planta y sus
                residentes.<br />Esta operación puede demorar unos
                segundos.</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :color="whiteLabel.colors.primary"
                  text
                  @click="closeDelete"
                  :disabled="loading"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="red darken-1"
                  text
                  @click="deleteItemConfirm"
                  :loading="loading"
                  >Eliminar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.dragRow="{}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" dense class="sortHandler">
              mdi-drag-horizontal-variant
            </v-icon></template
          >
          <span>Reordenar</span>
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon></template
          >
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              @click="
                $router.push({
                  name: 'units',
                  params: { initial_floor: item.id },
                })
              "
            >
              fa-th
            </v-icon>
          </template>
          <span>Ir a Unidades de la Planta</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db, ff, firebase } from "@/fb";
import { mapGetters } from "vuex";
import Sortable from "sortablejs";

export default {
  data: () => ({
    loading: false,
    floors: [],
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "",
        align: "start",
        sortable: false,
        value: "dragRow",
        width: "1%",
      },
      { text: "Planta", align: "start", sortable: false, value: "name" },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      id: "",
    },
    defaultItem: {
      name: "",
      id: null,
    },
    noData: "Buscando plantas ...",
    requiredRuleName: [(v) => !!v || "El Nombre es requerido"],
    isValid: false,
  }),

  mounted() {
    this.snackbar.visible = true;
    this.snackbar.text = "Buscando plantas ...";
    this.snackbar.color = "success";
    this.getFloors();
  },

  computed: {
    ...mapGetters({
      user: "user",
      whiteLabel: "whiteLabel",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Planta" : "Editar Planta";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async getFloors() {
      try {
        await db
          .collection("buildings/" + this.user.data.building + "/floors")
          .get()
          .then((floors) => {
            this.floors = [];
            let floor_positions = null;

            db.doc("buildings/" + this.user.data.building)
              .get()
              .then((floor) => {
                floor_positions = floor.data().floor_positions;
                let unsorted_floors = [];
                floors.forEach((floor) => {
                  unsorted_floors.push(floor);
                });

                floor_positions.forEach((floor) => {
                  let f = unsorted_floors.find((f) => f.id === floor);
                  this.floors.push({ name: f.data().name, id: f.id });
                });

                this.noData = "No hay plantas existentes";
              });
          });
      } catch (error) {
        console.log(error);
      }
    },

    inputChanged() {
      if (this.$refs.form.validate()) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },

    editItem(item) {
      this.isValid = false;
      this.editedIndex = this.floors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.floors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      this.snackbar.visible = true;
      this.snackbar.text = "Eliminando planta ...";
      this.snackbar.color = "success";

      const data = { floor_id: this.editedItem.id };
      ff.httpsCallable("deleteFloor")(data)
        .then((result) => {
          if (!result.data.error) {
            this.snackbar.visible = true;
            this.snackbar.text = "Planta eliminada...";
            this.snackbar.color = "success";
            this.floors.splice(this.editedIndex, 1);
          } else {
            this.snackbar.visible = true;
            this.snackbar.text =
              "Hubo un error al eliminar la planta. Se canceló la operación.";
            this.snackbar.color = "error";
            console.log(
              "error deleting floor",
              result.data.error.code + " - " + result.data.error.message
            );
          }
          this.loading = false;
          this.closeDelete();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.isValid = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      // Edicion
      if (this.editedIndex > -1) {
        this.loading = true;
        this.snackbar.visible = true;
        this.snackbar.text = "Guardados cambios...";
        this.snackbar.color = "success";
        let id = this.editedItem.id;
        let name = this.editedItem.name;
        db.doc("buildings/" + this.user.data.building + "/floors/" + id)
          .update({ name: name })
          .then(() => {
            this.loading = false;
            Object.assign(this.floors[this.editedIndex], this.editedItem);
            this.close();
          });
      } else {
        // Creacion
        this.loading = true;
        this.snackbar.visible = true;
        this.snackbar.text = "Agregado planta ...";
        this.snackbar.color = "success";
        let name = this.editedItem.name;
        db.collection("buildings/" + this.user.data.building + "/floors/")
          .add({ name: name, apartment_positions: [] })
          .then((floor) => {
            db.doc("buildings/" + this.user.data.building)
              .update({
                floor_positions: firebase.firestore.FieldValue.arrayUnion(
                  floor.id
                ),
              })
              .then(() => {
                this.loading = false;
                this.floors.push({ name: name, id: floor.id });
                this.close();
              });
          });
      }
    },

    saveOrder(event) {
      this.snackbar.visible = true;
      this.snackbar.text = "Orden de plantas actualizado ...";
      this.snackbar.color = "success";
      const movedItem = this.floors.splice(event.oldIndex, 1)[0];
      this.floors.splice(event.newIndex, 0, movedItem);
      const new_positions = [];
      this.floors.forEach((floor) => {
        new_positions.push(floor.id);
      });
      db.doc("buildings/" + this.user.data.building)
        .update({ floor_positions: new_positions })
        .then(() => {
          //
        });
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          handle: ".sortHandler",
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sortHandler {
  &:hover {
    cursor: ns-resize;
  }
}
</style>
